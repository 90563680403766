<template>
		
	<section class="contact mt-5" id="contact">
		<div class="container">
			<div class="main">
				<div class="row">
					<div class="col-lg-8 left">
						<h3>Send Message</h3>

						<form>
							<div class="row">
								<div class="col-sm-6">
									<input type="text" class="form-control" placeholder="Name">
								</div>
								<div class="col-sm-6">
									<input type="email" class="form-control" placeholder="Email">
								</div>
								<div class="col-sm-12">
									<input type="text" class="form-control" placeholder="Subject">
								</div>
							</div>
							<div class="form-group">
								<textarea class="form-control" rows="5" id="comment" placeholder="Message"></textarea>
							</div>
							<button class="btn btn-block" type="submit">Send Now!</button>
						</form>
					</div>
					<!-- Left -->
					<div class="col-lg-4">
						<div class="right">
							<h4>Contact Information</h4>
							<div class="info d-flex align-items-center">
								<i class="fa fa-map-marker" aria-hidden="true"></i>
								<span>c - 165, Your Street Jaipur, IN 91 554</span>
							</div>
							<div class="info d-flex align-items-center">
								<i class="fa fa-phone" aria-hidden="true"></i>
								<span>+1-456-456-7890</span>
							</div>
							<div class="info d-flex align-items-center">
								<i class="fa fa-envelope-open" aria-hidden="true"></i>
								<span>info@yourdomain.com</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<style type="text/css" scoped="">
	
.contact {
		padding-bottom: 100px;
}
.contact .main {
		background: #ffffff;
		box-shadow: 1px 1px 20px 0 rgba(0,0,0,0.08);
}
.contact .main h3 {
		font-size: 20px;
		font-weight: 500;
		color: #4a545e;
		margin: 0;
		margin-bottom: 20px;
}
.contact .form-control {
		padding: 25px;
		font-size: 13px;
		margin-bottom: 10px;
		background: #f8f8f9;
		border: 0;
		border-radius: 5px;
}
.contact button.btn {
		padding: 10px;
		border-radius: 5px;
		font-size: 15px;
		font-weight: bold;
		background-color: #bc2e2e;
		color: #ffffff;
}

.contact .main .left {
		padding: 40px 60px;
}
.contact .main .right {
		background:#bc2e2e;
		background-size: cover;
		padding: 40px;
		color: #ffffff;
		height: 100%;
}
.contact .main .right h4 {
		font-size: 20px;
		font-weight: 700;
		margin-bottom: 30px;
}
.contact .main .right .info {
		margin-bottom: 35px;
}
.contact .main .right .info i {
		font-size: 25px;
		color: #ffffff;
}
.contact .main .right .info span {
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		margin-left: 15px;
}
</style>

<script type="text/javascript">
	export default{
		name: 'Contact'
	}
</script>